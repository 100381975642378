body {
  margin: 0px;
  padding: 0px;
  color: #fff;
  background-color: #307ECC;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}

audio {
  width: 100% !important;
}

.audio-recorder.recording {
  width: 100% !important;
  background-color: orange !important;
}

.audio-recorder.recording>.audio-recorder-mic {
  display: none;
}

.audio-recorder {
  background-color: red !important;
  margin-bottom: 10%;
  box-shadow: 2px 2px 14px #00ff0a !important;
  border-radius: 35px !important;
  box-sizing: border-box;
  padding: 17px !important;
  width: 50px !important;
}

.audio-recorder-timer
{
  margin-left: 30px !important;
}
.audio-recorder-mic{
  padding: 0 !important;
}


.audio-recorder>* {
  margin-right: 10%;
  transform: scale(1.5);
}

.audio-recorder-options {
  margin-left: 0 !important;
}

.cell-verified {
  color: green;
}

.cell-not-verified {
  color: red;
}

.loader-gif{
  margin-left: 40%;
  width: 20%;
  height: 20%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  color: white;
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #307ECC inset;
}

input{
  color: white !important;
}

.edit-user input, .edit-subscription input {
  color: black !important;
}

/* util class */

@media only screen and (min-width: 200px) and (max-width: 768px) {}

@media only screen and (min-width: 768px) and (max-width: 1024px) {}

@media only screen and (min-width: 1024px) {}




.admterm{
  padding:50px;
  text-align: center;
}
.admterm textarea{
  border:1px dashed #8a8a8a;
  padding:20px;
  background:#eeeeee;
}
.admbtn{
  background:#128902;
  color: #f5f3f3;
  padding:6px;
  margin-top:20px;
  cursor: pointer;
  border-radius: 6px;
  width:100px;
  font-weight: 600;
  border-color: transparent;
}
.admtms{
  margin:20px auto;
  width:67%;
  text-align: center;
  background:#efefef;
  padding:10px;

}